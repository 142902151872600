import React, { useEffect, useState } from "react";
import helperSession from "../../../js/api/helpers/helpersSession";
import ApiRedireccionArirang from "../../../js/api/env/global/RedireccionArirang";
import ApiRedireccionJoyeria from "../../../js/api/env/global/RedireccionJoyeria";
import { openNotification } from "../../controls/Notifications";
import "./home.css";

import { Card, Col, Row, Typography } from "antd";

const { Title } = Typography;

const Home = () => {
  const [clicksArirang, setClicksArirang] = useState([]);
  const [clicksJoyeria, setClicksJoyeria] = useState([]);

  useEffect(() => {
    Promise.all([
      ApiRedireccionArirang.Clicks(),
      ApiRedireccionJoyeria.Clicks(),
    ])
      .then((res) => {
        const clicksArirang = res[0];
        const clicksJoyeria = res[1];
        if (clicksArirang.data.success && clicksJoyeria.data.success) {
          console.log(clicksArirang);
          setClicksArirang(clicksArirang.data.data);
          setClicksJoyeria(clicksJoyeria.data.data);
        } else {
          openNotification(
            "e",
            "Carga de Datos",
            "Ocurrio un error en la carga de datos"
          );
        }
      })
      .catch((err) => {
        openNotification(
          "e",
          "Error de sistema",
          "Ocurrio un error en el sistema"
        );
      });
  }, []);
  return (
    <div>
      <div className="title">
        <Title>
          <span>
            Hola{" "}
            {helperSession.GetAccount("nombre") +
              " " +
              helperSession.GetAccount("apellido")}
            , espero que tengas un buen día.
          </span>
        </Title>
      </div>
      <div className="site-card-wrapper">
        <Row>
          <Col style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <Title level={3}>Estadisticas En Arirang</Title>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col xs={24} sm={24} md={12} lg={8} span={8}>
            <Card
              hoverable
              size="small"
              title="Clicks Hoy"
              bordered={true}
              style={{ width: 200, marginBottom: 20 }}
            >
              <Title level={4}>{clicksArirang.clicksHoy}</Title>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} span={8}>
            <Card
              hoverable
              size="small"
              title="Clicks Ayer"
              bordered={true}
              style={{ width: 200, marginBottom: 20 }}
            >
              <Title level={4}>{clicksArirang.clicksAyer}</Title>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} span={8}>
            <Card
              hoverable
              size="small"
              title="Clicks Hace 7 Dias"
              bordered={true}
              style={{ width: 200, marginBottom: 20 }}
            >
              <Title level={4}>{clicksArirang.clicksSemanal}</Title>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: "40px", paddingBottom: "20px" }}>
            <Title level={3}>Estadisticas En Joyeria</Title>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col xs={24} sm={24} md={12} lg={8} span={8}>
            <Card
              hoverable
              size="small"
              title="Clicks Hoy"
              bordered={true}
              style={{ width: 200, marginBottom: 20 }}
            >
              <Title level={4}>{clicksJoyeria.clicksHoy}</Title>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} span={8}>
            <Card
              hoverable
              size="small"
              title="Clicks Ayer"
              bordered={true}
              style={{ width: 200, marginBottom: 20 }}
            >
              <Title level={4}>{clicksJoyeria.clicksAyer}</Title>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} span={8}>
            <Card
              hoverable
              size="small"
              title="Clicks Hace 7 Dias"
              bordered={true}
              style={{ width: 200, marginBottom: 20 }}
            >
              <Title level={4}>{clicksJoyeria.clicksSemanal}</Title>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
