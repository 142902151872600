import React from "react";
import DashboardHistorico from "./partial/DashboardHistoricoJoyeria";
import Dashboard30DiasJoyeria from "./partial/Dashboard30DiasJoyeria";
import Dashboard7DiasJoyeria from "./partial/Dashboard7DiasJoyeria";
import { Typography, Tabs } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import './DashboardJoyeria.css';

const { Title } = Typography;
const { TabPane } = Tabs;

const DashboardJoyeria = () => {
  return (
    <div>
      <Title level={2}>Interacciones En Joyeria Fernández</Title>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <CalendarOutlined />
              Ultimos 7 Dias
            </span>
          }
          key="1"
        >         
          <div className="chartContainer">
            <Title level={3}>Interacciones Ultimos 7 Dias</Title>
            <Dashboard7DiasJoyeria />
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              <CalendarOutlined />
              Ultimos 30 Dias
            </span>
          }
          key="2"
        >
          <div className="chartContainer">
            <Title level={3}>Interacciones Ultimos 30 Dias</Title>
            <Dashboard30DiasJoyeria />
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              <CalendarOutlined />
              Historicas
            </span>
          }
          key="3"
        >
          <div className="chartContainer">
            <Title level={3}>Interacciones Historicas</Title>
            <DashboardHistorico />
          </div>         
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DashboardJoyeria;
