import Axios from 'axios';
import ApiUrl from '../enviroment';


const DatoHistorico = async () => {
    const baseEndpoint = "/api/DashboardJoyeria/GraficoHistorico";
    return await Axios.get(ApiUrl + baseEndpoint); 
};

const Dato7Dias = async () => {
    const baseEndpoint = "/api/DashboardJoyeria/Grafico7Dias";
    return await Axios.get(ApiUrl + baseEndpoint); 
};

const Dato30Dias = async () => {
    const baseEndpoint = "/api/DashboardJoyeria/Grafico30Dias";
    return await Axios.get(ApiUrl + baseEndpoint); 
};

const ApiDashboardJoyeria = {
    DatoHistorico,
    Dato7Dias,
    Dato30Dias
}

export default ApiDashboardJoyeria;