import React from 'react';
import ApiLogin from '../../../js/api/env/global/auth/login'
import { Form, Input, Button } from 'antd';
import './css/login.css';

const Login = () => {
    const onFinish = (values) => {
        const { username, password } = values;
        ApiLogin.login(username, password);
      };
    return (
        <div className="back-login">
            <div className="login-container">
                <div className="container">
                    <h1>Login</h1>
                    <Form
                    // {...layout}
                    name="loginForm"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish = {onFinish}
                    >
                        <Form.Item
                            label="Usuario"
                            name="username"
                            rules={[
                              { required: true, message: "Por favor, ingrese su usuario" },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Contraseña"
                            name="password"
                            rules={[
                                { required: true, message: "Por favor, ingrese su contraseña" },
                            ]}
                            >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" shape="round" htmlType="submit">
                                Ingresar
                            </Button>
                        </Form.Item>
                    </Form>
                </div>           
            </div>
        </div>

    )
}

export default Login
