import React from "react";
import DashboardHistorico from "./partial/DashboardHistoricoArirang";
import Dashboard7DiasArirang from './partial/Dashboard7DiasArirang';
import Dashboard30DiasArirang from './partial/Dashboard30DiasArirang';
import { Typography, Tabs } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import './DashboardArirang.css';

const { Title } = Typography;
const { TabPane } = Tabs;
const DashboardArirang = () => {
  return (
    <div>
      <Title level={2}>Interacciones En Arirang</Title>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <CalendarOutlined />
              Ultimos 7 Dias
            </span>
          }
          key="1"
        >
          <Title level={3}>Interacciones Ultimos 7 Dias</Title>
          <div className="chartContainer">          
            <Dashboard7DiasArirang />
          </div>         
        </TabPane>
        <TabPane
          tab={
            <span>
              <CalendarOutlined />
              Ultimos 30 Dias
            </span>
          }
          key="2"
        >
          <div className="chartContainer">
            <Title level={3}>Interacciones Ultimos 30 Dias</Title>
            <Dashboard30DiasArirang />
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              <CalendarOutlined />
              Historicas
            </span>
          }
          key="3"
        >
          <Title level={3}>Interacciones Historicas</Title>
          <DashboardHistorico />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DashboardArirang;
