import React , { useEffect, useState } from 'react';
import Datatable from './Datatable'
import ApiRedireccionArirang from "../../../../../js/api/env/global/RedireccionArirang";
import { openNotification } from "../../../../controls/Notifications";

const Data = () => {
    const [loading, setLoading] = useState(false)
    const [dataRegistro, setDataRegistro] = useState([]);
    useEffect(() => {
      setLoading(true);
      Promise.all([ApiRedireccionArirang.DatosArirang()])
        .then((res) => {
          const resDataRegistro= res[0];
          if (resDataRegistro.data.success) {
            setDataRegistro(resDataRegistro.data.dataList);
          } else {
            openNotification(
              "e",
              "Carga de Datos",
              "Ocurrio un error en la carga de datos"
            );
          }
        })
        .catch((err) => {
          openNotification(
            "e",
            "Error de sistema",
            "Ocurrio un error en el sistema: "+err
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);
    const columns = [
      {
        title: "Nombre Vendedor",
        dataIndex: "nombreVendedor",
        key: "nombreVendedor",
        search: true
      },
      {
        title: "IP",
        dataIndex: "ip",
        key: "ip",
        search: true
      },
      {
        title: "Navegador",
        dataIndex: "navegador",
        key: "navegador",
        search: true
      },
      {
        title: "Fecha del Evento",
        dataIndex: "fechaRedireccion",
        key: "fechaRedireccion",
        search: true
      },
      {
        title: "Sistema Operativo",
        dataIndex: "os",
        key: "os",
        search: true
      },
      {
        title: "Dispositivo",
        dataIndex: "dispositivo",
        key: "dispositivo",
        search: true
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
        search: true
      },
      {
        title: "Ciudad",
        dataIndex: "ciudad",
        key: "ciudad",
        search: true
      },
      {
        title: "Pais",
        dataIndex: "pais",
        key: "pais",
      },
    ]

    return(      
      <Datatable data = {dataRegistro} columns = {columns} loading = {loading}/>
    )
}

export default Data


