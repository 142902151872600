import React, {Component} from 'react';
import {Routes, Route} from 'react-router'
import {BrowserRouter} from "react-router-dom";
import { getSessionAccount } from './js/api/helpers/helpersSession';

import NotFound from './components/pages/404/NotFound';
import Home from './components/pages/home/Home';
import Login from './components/pages/login/Login';
import LayoutLogin from './components/pages/layouts/LayoutLogin';
import DashboardArirang from './components/pages/Arirang/Dashboard/DashboardArirang';
import DashboardJoyeria from './components/pages/Joyeria/Dashboard/DashboardJoyeria';
import ReportesArirang from './components/pages/Arirang/Reports/ReportesArirang';
import ReportesJoyeria from './components/pages/Joyeria/Reports/ReportesArirang';

export default class App extends Component {
  static displayName = App.name;

  render(){
    return (
      <>
        <BrowserRouter>
        {getSessionAccount("token") ? ( 
          <LayoutLogin>
            <Routes>
              <Route exact path={`/ReportesJoyeria`} element={<ReportesJoyeria/>}/>
              <Route exact path={`/ReportesArirang`} element={<ReportesArirang/>}/>
              <Route exact path={`/DashboardJoyeria`} element={<DashboardJoyeria/>}/> 
              <Route exact path={`/DashboardArirang`} element={<DashboardArirang/>}/> 
              <Route exact path={`/Login`} element={<Home/>}/>              
              <Route exact path={`/Home`} element={<Home/>}/>
              <Route exact path={`/`}element={<Home/>}/>
              <Route path="*" element={<NotFound/>}/>
            </Routes> 
          </LayoutLogin>                
        ) : ( 
          <Routes>
              <Route exact path={`/Login`} element={<Login/>}/>
              <Route exact path={`/`} element={<Login/>}/>
              <Route path="*" element={<NotFound/>}/>
          </Routes>
        )}          
        </BrowserRouter>
      </>  
      );
  }
}
