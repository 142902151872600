import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Affix, Layout, Menu } from 'antd';
import { handlerLogout } from '../../../js/api/env/global/auth/logout'
import {
  FileSearchOutlined,
  PieChartOutlined,
  PoweroffOutlined,
  HomeOutlined
} from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default class Navbar extends Component {
    state = {
        collapsed: false,
      };
    
      onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
      };
      render(){
        const { collapsed } = this.state;
        return (
          <>
            <Affix>
              <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={this.onCollapse}
                style={{ backgroundColor: "#5631DE", height: "100vh" }}
              >
                <div className="logo">
                  <h1>
                    <span className="logo-titulo">LOGO</span>
                  </h1>
                </div>
                <Menu
                  theme="dark"
                  defaultSelectedKeys={["sub1"]}
                  mode="inline"
                  style={{ backgroundColor: "#5631DE" }}
                >
                  <Menu.Item key="1" icon={<HomeOutlined/>}>
                      <Link to="/Home">Inicio</Link>
                    </Menu.Item>
                  <SubMenu
                    key="sub1"
                    icon={<FileSearchOutlined />}
                    title="Reporte Individual"
                  >
                    <Menu.Item key="2">
                      <Link to="/ReportesArirang">Arirang</Link>
                    </Menu.Item>
                    <Menu.Item key="3">
                      <Link to="/ReportesJoyeria">Joyeria Fernandez</Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub2"
                    icon={<PieChartOutlined />}
                    title="Dashboard"
                  >
                    <Menu.Item key="4">
                      <Link to="/DashboardArirang">Arirang</Link>
                    </Menu.Item>
                    <Menu.Item key="5">
                      <Link to="/DashboardJoyeria">Joyeria Fernandez</Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item
                    key="6"
                    icon={<PoweroffOutlined />}
                    onClick={handlerLogout}
                  >
                    Cerrar Sesion
                  </Menu.Item>
                </Menu>
              </Sider>
            </Affix>           
          </>
        );
      }
  
}
