import Axios from "axios";
import ApiUrl from "../../enviroment";
import helperSession from '../../../helpers/helpersSession'
import { openNotification } from "../../../../../components/controls/Notifications";

const baseEndpoint = "/api/Login";
const login = async (nick, pass) => {
  const formData = new FormData();
  formData.append("nick", nick);
  formData.append("password", pass);
  return await Axios.post(
    ApiUrl + baseEndpoint,
    formData
  ).then((data) => {
        if(!helperSession.GetAccount("token")){
          if (data.data.success) {
            saveAuthData(data.data.data);
          } else {
            openNotification('e', data.data.subStatus, data.data.message)
          }
        }else{
          window.history.replaceState({}, document.title, window.location.pathname);
        }         
  }).catch((err) => {
    openNotification('e', 'Error en la conexion', 'Error en la conexion al servidor');
    console.log(err);
  });
};

const saveAuthData = async (data) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  helperSession.SetAccount("token", data.token);
  helperSession.SetAccount("uuid", data.uuid);
  helperSession.SetAccount("nick", data.nick);
  helperSession.SetAccount("nombre", data.nombres);
  helperSession.SetAccount("apellido", data.apellidos);
  window.location = "/home";
};

const ApiLogin = {
  login,
};

export default ApiLogin;
