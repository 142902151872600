import Axios from "axios";
import ApiUrl from "../../env/enviroment";

const baseEndpoint = "/api/RedireccionArirang/Clicks";
const baseEndpointArirang = "/api/RedireccionArirang/ObtenerTabla";
const Clicks = async () => {
    return await Axios.get(ApiUrl + baseEndpoint); 
}

const DatosArirang = async () => {
    return await Axios.get(ApiUrl + baseEndpointArirang); 
}

const ApiRedireccionArirang = {
    Clicks,
    DatosArirang
}
export default ApiRedireccionArirang;

