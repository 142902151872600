import Axios from 'axios';
import ApiUrl from '../enviroment';


const DatoHistorico = async () => {
    const baseEndpoint = "/api/DashboardArirang/GraficoHistorico";
    return await Axios.get(ApiUrl + baseEndpoint); 
};

const Dato7Dias = async () => {
    const baseEndpoint = "/api/DashboardArirang/Grafico7Dias";
    return await Axios.get(ApiUrl + baseEndpoint); 
};

const Dato30Dias = async () => {
    const baseEndpoint = "/api/DashboardArirang/Grafico30Dias";
    return await Axios.get(ApiUrl + baseEndpoint); 
};

const ApiDashboardArirang = {
    DatoHistorico,
    Dato7Dias,
    Dato30Dias
}

export default ApiDashboardArirang;
