import React, { useEffect, useState } from "react";
import ApiDashboardJoyeria from "../../../../../js/api/env/global/DashboardJoyeria";
import { openNotification } from "../../../../controls/Notifications";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const Dashboard30DiasJoyeria = () => {
    const [data30Dias, setData30Dias] = useState([]);
    useEffect(() => {
      Promise.all([ApiDashboardJoyeria.Dato30Dias()])
        .then((res) => {
          const dashboard30Dias = res[0];
  
          if (dashboard30Dias.data.success) {
              console.log(dashboard30Dias)
            setData30Dias(dashboard30Dias.data.dataList);
          } else {
            openNotification(
              "e",
              "Carga de Datos",
              "Ocurrio un error en la carga de datos"
            );
          }
        })
        .catch((err) => {
          openNotification(
            "e",
            "Error de sistema",
            "Ocurrio un error en el sistema"
          );
        });
    }, []);
  
    const resultadoDashboard30Dias = data30Dias.map(
      (data) => data.clicks
    );
    const labelDashboard30Dias = data30Dias.map(
      (data) => data.nombreRedireccion
    );
  
    const dataDashboard = {
      labels: labelDashboard30Dias,
      datasets: [
        {
          label: "Interacciones",
          backgroundColor: [
            //"rgb(42, 53, 245)",
            //"rgb(40, 102, 235)",
            "rgb(86, 49, 222)",
            "rgb(188, 40, 235)",
            "rgb(141, 42, 245)",
            "rgb(177, 45, 224)",
            "rgb(247, 37, 245)",
            "rgb(237, 36, 134)",
            "rgb(247, 84, 144)",
            "rgb(147, 84, 244)",
          ],
          borderColor: "rgba(141,42,245,0.4)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(141,42,245,0.4)",
          hoverBorderColor: "rgba(141,42,245,0.4)",
          data: resultadoDashboard30Dias,
        },
      ],
    };
    return (
        <div>
            <Doughnut data={dataDashboard}/>
        </div>
    );
}

export default Dashboard30DiasJoyeria
