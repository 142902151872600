import React from "react";
import { Table, Typography } from "antd";

const {Title} = Typography;
const Datatable = ({columns, data}) => {

  return (
    <div>
      <Title level={3}>Detalle de las redirecciones</Title>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 1000, y:400 }}
        bordered
      />
    </div>

  );
};

export default Datatable;
